<template>
        <div class="sun_bg">
        
        <navigation-bar></navigation-bar>
        
        <div class="content">
            <div class="row">
              <div class="col-xl-7  mt-5">
                <h1>Hoeveel kan jij besparen met zonne-energie?</h1>
                <p>Maak de online berekening via onze calculator en ontdek hoeveel je kan besparen met zonnepanelen.</p>
                <router-link to="/calculator"><button type="button" class="btn btn-primary mt-4">Start berekening<i class="fa-solid fa-chevron-right"></i></button></router-link>
              </div>
              <div class="col-xl-5">
                <img class="header_img" src="../../assets/Family.png" alt="Illustration of buildings in city">
              </div>
            </div>
          </div>
          
        </div>
</template>
        
<script>
        import NavigationBar from '../../components/NavigationBar.vue';
        
        export default {
          name: 'HomeView',
          components: {
            NavigationBar
          }
        }
</script>

<style>

</style>