<template>
      <div class="sun_bg">
      
      <navigation-bar></navigation-bar>
      
      <div class="content">
        <div class="row">
            <div class="col-xl-6 mb-3">
              <h1>Vraag vrijblijvend een offerte aan</h1>
              <p>De kracht van de zon is binnen handbereik. Laat uw gegevens achter en we nemen zo snel mogelijk contact met u op.</p>
  
              <div class="container p-3">
                <div class="row">
                  <div class="col-12 col-xxl-7">
                  <h3>Aantal zonnepanelen</h3>
                  <b>Vermogen 430wp</b>
                </div>
                  <div class="col-12 col-xxl-5">
                    <div class="input-group">
                        <button type="button" class="btn btn-outline-secondary" @click="formData.aantalZonnepanelen > 0 ? formData.aantalZonnepanelen-- : formData.aantalZonnepanelen">-</button>
                        <input type="number" class="form-control text_center" placeholder="0" aria-label="Input group example" aria-describedby="btnGroupAddon" v-model="formData.aantalZonnepanelen"  @input="formData.aantalZonnepanelen = Math.max(0, Math.round(formData.aantalZonnepanelen))" min="0">
                        <button type="button" class="btn btn-outline-secondary" @click="formData.aantalZonnepanelen++">+</button>
                  </div>
                  </div>
                </div>
              </div>
              <div class="container p-3">
                  <div class="row">
                  <div class="col-9 col-xxl-11">
                    <b>Thuisbatterij toevoegen aan offerte?</b>
                  </div>
                  <div class="col-2 col-xxl-1">
                    <input type="checkbox" class="checkbox" id="batterij" name="batterij" v-model="formData.thuisbatterij">
                  </div>
                </div>
              </div>
            </div>
  
            <div class="col-xl-5 offset-xl-1">
            <div class="container p-3">
              <div v-if="!formSubmitted">
              <h2>Uw gegevens</h2>
              <form class="row g-3" @submit.prevent="sendEmail" ref="form">
                <div class="col-md-6">
                  <label for="inputFirstname" class="form-label">Voornaam (*)</label>
                  <input type="text" class="form-control" id="inputFirstname" v-model="formData.firstname"  required>
                </div>
                <div class="col-md-6">
                  <label for="inputName" class="form-label">Naam (*)</label>
                  <input type="text" class="form-control" id="inputName" v-model="formData.name" required>
                </div>
                <div class="col-12">
                  <label for="inputCompanyname" class="form-label">Bedrijfsnaam (optioneel)</label>
                  <input type="text" class="form-control" id="inputCompanyname" v-model="formData.companyname">
                </div>
                <div class="col-12">
                  <label for="inputEmail" class="form-label">E-mailadres (*)</label>
                  <input type="email" class="form-control" id="inputEmail" v-model="formData.email" required>
                </div>
                <div class="col-12">
                  <label for="inputMessage" class="form-label">Bericht (optioneel)</label>
                  <textarea class="form-control" id="inputMessage" rows="3" v-model="formData.message"></textarea>
                </div>
                <div class="col-12">
                  <button type="submit" class="btn btn-primary">Verzenden<i class="fa-solid fa-chevron-right"></i></button>
                </div>
              </form>
              </div>
              <div class="center" v-else>
              <h2>Bedankt, {{ formData.firstname }}</h2>
              <b>We hebben uw offerte-aanvraag goed ontvangen en nemen zo snel mogelijk contact met u op.</b>
              <div class="row mt-3">
                <lord-icon
                src="https://cdn.lordicon.com/ihyatngg.json"
                trigger="loop"
                delay="1000"
                colors="primary:#F49722,secondary:#FCDC28"
                style="width:300px;height:300px">
              </lord-icon>
              </div>
              

              </div>
            </div>
          </div>
          </div>    
      </div>
      </div>
      </template>
              
<script>
import NavigationBar from '../../components/NavigationBar.vue';
import axios from 'axios';

              
export default {
      name: 'HomeView',
      created(){
        this.setFormDataFromQueryParams();
      },
      components: {
            NavigationBar
      },
      data(){
        return{
          aantalZonnepanelen: null,
          formData: {
            firstname: '',
            name: '',
            companyname: '',
            email: '',
            message: '',
            aantalZonnepanelen: null,
            thuisbatterij: false,
          },
          formSubmitted: false
        }
      },
      methods:{
        setFormDataFromQueryParams(){
          const query = this.$route.query;
          if (query.aantalZonnepanelen !== undefined) {
            this.formData.aantalZonnepanelen = parseInt(query.aantalZonnepanelen, 10) || 0;
          }
        },
        async sendEmail() {
        try {
        await axios.post('https://formspree.io/f/mjvqrzyq', this.formData);
          this.formSubmitted = true;
        } catch (error) {
        console.error(error);
          alert("Formulier niet verzonden. Probeer opnieuw.")
        }
        } 
      }
}
</script>

<style>

</style>