import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router';

import '../public/css/bootstrap.css'

import App from './App.vue'

import ZonnepanelenIntro from './views/Zonnepanelen/IntroView.vue'
import ZonnepanelenCalculator from './views/Zonnepanelen/CalculatorView.vue'
import ZonnepanelenOfferte from './views/Zonnepanelen/OfferteView.vue'



const router = createRouter({
    history: createWebHistory(),
    mode: 'history',
    initialRoute: 'home',
    routes: [
        {path: '/', redirect: '/home'},
        {path: '/:notFound(.*)', redirect: '/'},
        {
            path: '/home',
            name: 'home',
            component: ZonnepanelenIntro,
            props: true
        },
        {
            path: '/calculator',
            name: 'Calculator',
            component: ZonnepanelenCalculator,
            props: true
        },
        {
            path: '/offerte',
            name: 'Offerte',
            component: ZonnepanelenOfferte,
            props: true
        },
        
    ]
})


const app = createApp(App);
app.use(router);
app.mount('#app');



