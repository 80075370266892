<template>
<div class="sun_bg" id="stap1">

<navigation-bar></navigation-bar>

<div class="content">
  <div class="row">
          <div class="col-xl-6">
            <h1>Calculator</h1>
            <p>Stap 1: Huidige situatie</p>

            <!--Prijs per kwh-->
            <div class="row mt-4">
              <div class="col-5 align-self-center">
                <d>Prijs per kWh:</d>
              </div>
              <div class="col-7">
                <div class="input-group">
                  <span class="input-group-text">€</span>
                  <input type="number" class="form-control text_center"  aria-label="Input group example" aria-describedby="btnGroupAddon" v-model="prijsPerkWh" @input="prijsPerkWh = Math.max(0, prijsPerkWh)" min="0">
                </div>
              </div>
            </div>

            <!--Stroomverbruik-->
            <div class="row mt-4">
              <div class="col-5 align-self-center">
                <d>Stroomverbruik op jaarbasis:</d>
              </div>
              <div class="col-7">
                <div class="input-group">
                  <input type="number" class="form-control text_center"  aria-label="Input group example" aria-describedby="btnGroupAddon" v-model="stroomverbruik" @input="stroomverbruik = Math.max(0, Math.round(stroomverbruik))" min="0">
                  <span class="input-group-text">kWh</span>
                </div>
              </div>
            </div>


            <div class="row mt-4 mb-4 mb-xl-0 cards">
              <d class="mb-1">Wat is de hellingsgraad van uw dak?</d>
              <div class="col-12 col-md-3">
                <a @click="daksoort ='Plat'" :class="{ activecard: daksoort === 'Plat' }" class="container p-3 center">
                  <img src="../../assets/plat.png" class="iconImage"  alt="">
                  <b class="mt-3">Plat dak</b>
                </a>
              </div>
              <div class="col-12 col-md-3">
                <a @click="daksoort ='Hellend'" :class="{ activecard: daksoort === 'Hellend' }" class="container p-3 center">
                  <img src="../../assets/hellend.png" class="iconImage"  alt="">
                  <b class="mt-3">Hellend dak</b>
                </a>
              </div>
              <div class="col-12 col-md-3">
                <a @click="daksoort ='Schuin'" :class="{ activecard: daksoort === 'Schuin' }" class="container p-3 center">
                  <img src="../../assets/schuin.png" class="iconImage"  alt="">
                  <b class="mt-3">Schuin dak</b>
                </a>
              </div>
            </div>

          </div>

          <div class="col-xl-5 offset-xl-1">
            <div class="row">
              <div class="dropdown d-grid gap-2">
                <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Selecteer oriëntatie</button>
                <ul class="dropdown-menu dropdown-menu-dark">
                  <li><a class="dropdown-item" @click="oriëntatie ='Noord'" :class="{ active: oriëntatie === 'Noord' }">Noord</a></li>
                  <li><a class="dropdown-item" @click="oriëntatie ='Oost'" :class="{ active: oriëntatie === 'Oost' }">Oost</a></li>
                  <li><a class="dropdown-item" @click="oriëntatie ='Zuid'" :class="{ active: oriëntatie === 'Zuid' }">Zuid</a></li>
                  <li><a class="dropdown-item" @click="oriëntatie ='West'" :class="{ active: oriëntatie === 'West' }">West</a></li>
                </ul>
              </div>
            </div>
            <div class="row">
              <img v-if="daksoort==='Hellend'" class="img_light mt-5" src="../../assets/hellend.png" alt="Dak">
              <img v-else-if="daksoort==='Plat'" class="img_light mt-5" src="../../assets/plat.png" alt="Dak">
              <img v-else-if="daksoort==='Schuin'" class="img_light mt-5" src="../../assets/schuin.png" alt="Dak">


            </div>
          </div>
        </div>
        <div class="mt-4">
          <router-link to="/"><button class="btn btn-outline-primary mb-3 mb-xl-0"><i class="fa-solid fa-circle-xmark"></i>Annuleren</button></router-link>
          <a href="#stap2"><button class="btn btn-primary mb-4 mb-xl-0" @click="huidigesituatie = true">Volgende<i class="fa-solid fa-chevron-right"></i></button></a>
        </div>
  </div>
</div>

<div v-if="huidigesituatie" class="light_bg" id="stap2">
  <div class="content">
        <h1 class="mt-3 mt-xl-0">Calculator</h1>
        <p>Stap 2: Gewenste situatie</p>
        <div class="row">
          <div class="col-xl-6">
            <div class="card">
              <img src="../../assets/benjamin-jopen-p2GuLUu79Rg-unsplash.png" class="card-img" alt="LED Ufo Waterdicht ">
              <div class="card-img-overlay">
                <div class="container p-3">
                  <div class="row">
                    <div class="col-12 col-xxl-7">
                      <h3>Aantal zonnepanelen</h3>
                      <b>Vermogen {{vermogen}}wp</b>
                    </div>
                    <div class="col-12 col-xxl-5">
                      <div class="input-group">
                        <button type="button" class="btn btn-outline-secondary" @click="aantalZonnepanelen > 0 ? aantalZonnepanelen-- : aantalZonnepanelen">-</button>
                        <input type="number" class="form-control text_center" placeholder="0" aria-label="Input group example" aria-describedby="btnGroupAddon" v-model="aantalZonnepanelen"  @input="aantalZonnepanelen = Math.max(0, Math.min(this.maxAantal, Math.round(aantalZonnepanelen)))" min="0">
                        <button type="button" class="btn btn-outline-secondary" @click="aantalZonnepanelen < this.maxAantal ? aantalZonnepanelen++ : aantalZonnepanelen">+</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-5 offset-xl-1 mt-2 mt-xl-0">
            <h2>Hoeveel zonnepanelen kan ik op mijn dak leggen?</h2>
            <p>Bereken de oppervlakte van mijn dak.</p>

            <!--Basis-->
            <div class="row mt-4">
              <div class="col-2 align-self-center">
                <b>Basis</b>
              </div>
              <div class="col-7 col-xl-4 offset-1 offset-xl-0">
                <div class="input-group">
                  <input type="number" class="form-control text_center"  aria-label="Input group example" aria-describedby="btnGroupAddon" v-model="basis" @input="basis = Math.max(0, basis)" min="0">
                  <span class="input-group-text">m</span>
                </div>
              </div>
            </div>

            <!--Hoogte-->
            <div class="row mt-4">
              <div class="col-2 align-self-center">
                <b>Hoogte</b>
              </div>
              <div class="col-7 col-xl-4 offset-1 offset-xl-0">
                <div class="input-group">
                  <input type="number" class="form-control text_center"  aria-label="Input group example" aria-describedby="btnGroupAddon" v-model="hoogte" @input="hoogte = Math.max(0, hoogte)" min="0"> 
                  <span class="input-group-text">m</span>
                </div>
              </div>
            </div>
            <div class="row mt-5 mb-5 mb-xl-0">
              <button class="btn btn-primary" @click="berekenMaxAantal()">Update aantal zonnepanelen<i class="fa-solid fa-chevron-right"></i></button>
            </div>
          </div>
        </div>
        <a href="#stap1"><button class="btn btn-outline-primary mb-3 mb-xl-0"><i class="fa-solid fa-chevron-left"></i>Vorige</button></a>
        <a href="#stap3"><button class="btn btn-primary mb-3 mb-xl-0" @click="gewenseSituatie = true">Berekenen<i class="fa-solid fa-chevron-right"></i></button></a>
      </div>
</div>

<div v-if="gewenseSituatie" class="sun_bg" id="stap3">
  <div class="content">
        <h1 class="mt-4">Resultaat</h1>
        <div class="card mt-4 mb-3">
          <div class="row g-0">
            <div class="col-md-5">
              <div class="card-body">
                <h1>Bespaar tot</h1>
                <h4>€{{berekendResultaat}}/jaar</h4>
                <h5>Huidige situatie</h5>
                <p><i class="fa-solid fa-bolt"></i>{{stroomverbruik}} kWh/jaar</p>
                <p><i class="fa-solid fa-house"></i>{{daksoort}} dak</p>
                <p><i class="fa-solid fa-compass"></i>{{oriëntatie}}gericht</p>

                <h5>Gewenste situatie</h5>
                <p v-if="aantalZonnepanelen===1"><i class="fa-solid fa-solar-panel"></i>{{aantalZonnepanelen}} zonnepaneel</p>
                <p v-else><i class="fa-solid fa-solar-panel"></i>{{aantalZonnepanelen}} zonnepanelen</p>
                <router-link :to="{path: '/offerte', query: {aantalZonnepanelen: aantalZonnepanelen}}"><button class="btn btn-primary">Vraag een offerte aan<i class="fa-solid fa-chevron-right"></i></button></router-link>
              </div>
            </div>
            <div class="col-md-7">
              <img src="../../assets/solar-energy-panel-and-light-bulb-green-energy-2021-09-03-18-58-11-utc.jpg" class="img-fluid rounded-end" alt="...">
            </div>
          </div>
        </div>
      </div>
</div>

<div></div>
</template>
        
<script>
import NavigationBar from '../../components/NavigationBar.vue';
        
export default {
  name: 'HomeView',
  components: {
    NavigationBar
  },
  data(){
    return{
      prijsPerkWh: 0.45,
      stroomverbruik: 3600,
      aantalZonnepanelen: 0,
      AantalbenodigdePanelen:0,
      maxAantal: Infinity,
      basis: 0,
      hoogte:0,
      daksoort: 'Plat',
      oriëntatie: 'Zuid',
      instralingsfactor: 0.8,

      //eigenschappen zonnepaneel
      vermogen: 430,
      hoogtePaneel: 1.70,
      breedtePaneel: 1.2,
      rendement: 1.1,

      huidigesituatie: false,
      gewenseSituatie: false,

    }
  },
  mounted(){
    this.instralingsfactor = this.berekenInstralingsfactor;
    this.aantalZonnepanelen = this.berekenAantalZonnepanelen;
    this.AantalbenodigdePanelen = this.berekenAantalZonnepanelen;
  },
  computed: {
    berekendResultaat() {
      let opbrengst = (this.vermogen / this.rendement) * this.instralingsfactor ;

      let resultaat;

      if(opbrengst * this.aantalZonnepanelen > this.stroomverbruik) {
        resultaat = this.stroomverbruik * this.prijsPerkWh * this.instralingsfactor;

      } else{
        resultaat = opbrengst * this.aantalZonnepanelen * this.prijsPerkWh * this.instralingsfactor;

      }

      if(resultaat < 0){
        resultaat = 0
      }

      return resultaat.toLocaleString('nl-NL', {
        decimalSeparator: ',',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    berekenAantalZonnepanelen(){
      let opbrengst = (this.vermogen / 1.1) * this.instralingsfactor;
      let berekendAantal = Math.ceil(this.stroomverbruik / opbrengst);

      if(this.aantalZonnepanelen > this.maxAantal){
        this.berekenMaxAantal();
      }
      return berekendAantal;
    },
    berekenInstralingsfactor(){
      let instralingsfactor;

      if(this.oriëntatie === "Noord"){
        if(this.daksoort ==="Plat"){
          instralingsfactor = 0.75
        } else if (this.daksoort === "Hellend"){
          instralingsfactor = 0.60
        } else { //schuin
          instralingsfactor = 0.45
        }
      } else if (this.oriëntatie === "Oost" || this.oriëntatie === "West"){
        if(this.daksoort ==="Plat"){
          instralingsfactor = 0.85
        } else if (this.daksoort === "Hellend"){
          instralingsfactor = 0.80
        } else { //schuin
          instralingsfactor = 0.75
        }
      } else { //zuid
        if(this.daksoort ==="Plat"){
          instralingsfactor = 0.95
        } else if (this.daksoort === "Hellend"){
          instralingsfactor = 1.00
        } else { //schuin
          instralingsfactor = 1.00
        }
      }
      return instralingsfactor;
    },

  },
  methods: {
    berekenMaxAantal(){
      let aantalInBreedte = Math.floor((this.basis - 0.60) / this.breedtePaneel);
      let aantalInHoogte = Math.floor((this.hoogte - 0.60) / this.hoogtePaneel);

      if(aantalInBreedte <= 0 || aantalInHoogte <= 0){
        this.maxAantal = 0;
      } else {
        this.maxAantal = aantalInBreedte * aantalInHoogte;
      }

      if (this.aantalZonnepanelen < this.AantalbenodigdePanelen) {
        this.aantalZonnepanelen = this.AantalbenodigdePanelen;
      }

      if(this.aantalZonnepanelen > this.maxAantal){
        this.aantalZonnepanelen = this.maxAantal;
      }
    }
  },
  watch:{
    stroomverbruik: function(){
      this.aantalZonnepanelen = this.berekenAantalZonnepanelen;
      this.AantalbenodigdePanelen = this.berekenAantalZonnepanelen;
    },
    prijsPerkWh: function(){
      this.aantalZonnepanelen = this.berekenAantalZonnepanelen;
      this.AantalbenodigdePanelen = this.berekenAantalZonnepanelen;
    },
    daksoort: function(){
      this.instralingsfactor = this.berekenInstralingsfactor;
      this.aantalZonnepanelen = this.berekenAantalZonnepanelen;
      this.AantalbenodigdePanelen = this.berekenAantalZonnepanelen;
    },
    oriëntatie: function(){
      this.instralingsfactor = this.berekenInstralingsfactor;
      this.aantalZonnepanelen = this.berekenAantalZonnepanelen;
      this.AantalbenodigdePanelen = this.berekenAantalZonnepanelen;
    },
  },
}
</script>

<style>
.iconImage{
  width: 100%;
}
.activecard{
  border: 3px solid #F49722;

}
.cards .container:hover:not(.activecard){
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}
</style>