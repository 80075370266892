<template>
  <nav class="navbar navbar-dark pt-4">
        <div class="container-fluid mx-5 p-0">
          <div class="col-8">
            <router-link to="/"><a class="navbar-brand"><img src="../assets/Max_Logo_Horizontal.svg" alt="MaxEnergie Logo"></a></router-link>
          </div>
          <div class="col-md-1 ">
            <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar">
            <span class="navbar-toggler-icon"></span>
          </button>
          </div>
          <div class="offcanvas offcanvas-end text-bg-dark" tabindex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
            <div class="offcanvas-header m-4">
              <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body mx-4">
              <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
                <li class="nav-item">
                  <router-link to="/calculator"><a a class="nav-link">Calculator</a></router-link>
                  
                </li>

                <li class="nav-item">
                  <router-link to="/offerte"><a a class="nav-link">Offerte</a></router-link>
                  
                </li>
                
              </ul>
            </div>
          </div>
        </div>
      </nav>
</template>

<script>
export default {

}
</script>

<style>

</style>