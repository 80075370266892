<template>
    <router-view></router-view>


  <div></div>
</template>

<script>

</script>

<style>
#app {
height: 100%;
overflow: auto;
scroll-behavior: smooth;
}

@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@200;400&family=IBM+Plex+Sans:wght@600&display=swap');
/*Fonts*/
h1 {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 38pt;
    color: #F6F7F8;
}
h2{
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 25;
    color: #181C28;
}
h3 {
    font-family: 'Heebo', sans-serif;
    font-size: 25;
    font-weight: 700;
    color: #F49722;
}
h4 {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 38pt;
    color: #F49722;
}

p, .dropdown-item{
    font-family: 'Heebo', sans-serif;
    font-weight: 300;
    color: #F6F7F8;
    font-size: 16pt;
}
b {
    font-family: 'Heebo', sans-serif;
    font-weight: 100;
    color: #181C28;
    font-size: 14pt;
}
d {
    font-family: 'Heebo', sans-serif;
    font-weight: 300;
    color: #F6F7F8;
    font-size: 14pt;
    margin: 0;
    padding: 0;
}
i {
    margin-left: 10px;
    margin-right: 10px;
}
a{
  text-decoration: none;
}

body, html {
    height: 100%;
    width: 100%;

}


/*Backgrounds*/

.sun_bg {
    background-image: url("./assets/Header_background_Sun4.png");
    min-height: 100%; 
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
}

.light_bg {
    background-color: #F6F7F8;
    color: #181C28 !important;
    min-height: 100%; 
}
.light_bg h1, .light_bg p, .card h1, .card p{
    color: #181C28;
}


/*Margins & Padding*/
.mx-5 {
    margin-left: 10% !important;
    margin-right: 10% !important;
}
.mt-5 {
    margin-top: 8% !important;
}
.content {
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 2%;
    padding-bottom: 2%;
}

/*Images*/
.header_img {
    width: 100%;
}

.img_light{
    width: 100%;
}


/*buttons*/
.btn, .input-group-text {
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    font-size: 14pt;
}
.btn-primary{
    background-image:linear-gradient(to right, #F49722, #FCDC28);
    border: solid 0px;
    border-radius: 10pt;

}
.btn-primary:hover{
    background-image:linear-gradient(to top, #F49722, #FCDC28);
}

.btn-outline-secondary, .input-group-text{
    border: solid 1px #181C28;
    background-color: #F6F7F8;
    color: #181C28;
    text-align: center;
}
.btn-outline-primary {
    border: solid 2px #F6F7F8 ;
    color: #F6F7F8;
    border-radius: 10pt;
    margin-right: 20px;
    background-color: transparent !important;


}
.light_bg .btn-outline-primary {
    border: solid 2px #181C28;
    color: #181C28;
}
.btn-outline-primary:hover{
    border: solid 2px #F49722;
    color: #F49722;
    background-color: transparent;
    border-radius: 10pt;
}
.btn-outline-secondary:hover{
    background-image:linear-gradient(to right, #F49722, #FCDC28);
}
button, span {
    font-family: 'IBM Plex Sans', sans-serif !important;
    text-transform: uppercase;
}


/*forms*/
.form-control{
    border: solid 1px #181C28;
    color: #181C28;
}
.text_center{
    text-align: center;

}
.input-group{
    max-width: 220px;
}
.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.dropdown-menu{
    width: 87%;
}


/*navbar*/
#offcanvasDarkNavbar, .dropdown-menu-dark{
    background-color: #181C28 !important;
}
.navbar-brand img {
    width: 200px;
}
.nav-link, .nav-title {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 22px;
    color: #F6F7F8;
    margin-top: 30px;
}
.nav-link:hover, .active{
    color: #F49722 !important;  
}
.navbar-toggler-icon {
    background-image: url(./assets/Asset\ 2Hamburger.svg);
}
.navbar-toggler-icon:hover {
    background-image: url(./assets/Asset\ 2Hamburger_hover.svg);
}
.btn-close {
    background-image: url(./assets/xmark-solid.svg);

}
.btn-close:hover {
    background-image: url(./assets/xmark-solid-hover.svg);

}
nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  nav ul ul a {
    width: 100%;
    display: list-item;
    position: relative;
    text-decoration: none;
    color: #F6F7F8;

    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 16pt;

  }
  nav ul ul a:hover {
    color: #F49722;

  }


/*Container & card*/
.container, .card {
    background-color: #F6F7F8;
    border: solid 0px;
    border-radius: 10pt;
    margin-bottom: 4%;
}
.card-img-overlay .container {
    margin-bottom: 0;
    
}


/*Range slider*/
.slidecontainer {
    margin-top: 20px;
    width: 100%;
}
.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 5px;
    background: linear-gradient(to left, rgba(253,248,219,1), #223145);
    outline: none;
}
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #F6F7F8;
    cursor: pointer;
  }
  
  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #04AA6D;
    cursor: pointer;
  }


/*Input - Checkbox*/
.checkbox {
    width: 100%;
    height: 100%;     
}


/*Verberg spinners in tekstvelden*/
input[type=number] {
-moz-appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/*Responsive*/
@media screen and (max-width: 600px) {
  h1{
    font-size: 26pt;
  }
  h4{
    font-size: 26pt;
  }
}
@media screen and (max-width: 1400px) {
  h1{
    font-size: 34pt;
  }
  h4{
    font-size: 34pt;
  }
}

</style>
